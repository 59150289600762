import React from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table } from 'antd'

import Pagination from '../../../components/Pagination'

export default function EmployeesTable({ employees, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'fullname'
		},
		{
			title: 'Cédula',
			dataIndex: 'document'
		},
		{
			title: 'Cargo',
			dataIndex: 'position'
		},
		{
			title: 'Empresa',
			dataIndex: 'company'
		},
		{
			title: 'Departamento',
			dataIndex: 'department_name'
		},
		{
			title: 'Categoría',
			dataIndex: 'employee_type',
			render: t => t ? t.name : <em>Sin categoría asociada</em>
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-2'><strong>Correo: </strong>{r.email}</p>
			<p className='mb-2'><strong>Tipo de Contrato: </strong>{r.contract_type}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={employees}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!employees}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}