import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import ReferralsList from './ReferralsList'

export default function Referrals() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/referrals" component={ReferralsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}