import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import SettingsHomePage from './SettingsHomePage'

export default function Settings() {
	return (
		<React.Fragment>
			<Switch>
				<Redirect exact path="/settings" to="/settings/points_factor" />
				
				<Route exact path="/settings/:tab" component={SettingsHomePage} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}