import React from 'react'
import { Form } from 'react-bootstrap'
import { Col, Row } from 'reactstrap'

export default function CouponOrderForm({ register, errors, watch }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Canal donde desea redimir <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register("channel", { required:true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="stores">Tiendas Spring</option>
					<option value="ecommerce" disabled>eCommerce Spring</option>
				</Form.Control>
				{ errors.channel && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Medio de pago <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register("payment_method", { required: true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="others">Otros medios de pago</option>
					<option value="salary">Descuento por nómina</option>
					<option value="mixed">Otros medios + Dcto nómina</option>
				</Form.Control>
				{ errors.payment_method && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			{ watch('payment_method') !== 'others' && (
				<Row>
					<Form.Group as={Col}>
						<Form.Label>Número de cuotas <span className='text-primary'>*</span></Form.Label>
						<Form.Control
							as="select"
							{...register("credit_payments", { required:true })}
						>
							<option value="">:: Seleccione una opción ::</option>
							<option value="1">1 Cuota</option>
							<option value="2">2 Cuotas</option>
							<option value="3">3 Cuotas</option>
							<option value="4">4 Cuotas</option>
							<option value="5">5 Cuotas</option>
							<option value="6">6 Cuotas</option>
						</Form.Control>
						{ errors.credit_payments && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
					</Form.Group>
				</Row>
			)}
		</React.Fragment>
	)
}