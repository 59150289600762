import React from 'react'
import { Form } from 'react-bootstrap'

export default function EmployeeTypeForm({ register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre de Categoría <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required:true })} 
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}