import springApi, { getDomain } from '../../services/springApi'

export const getIncentives = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/incentives', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getIncentive = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/incentives/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeIncentive = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/incentives', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateIncentive = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/incentives/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteIncentive = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/incentives/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchIncentives = async (name, token) => (
	fetch( getDomain()+`/incentives?filter[name]=${name}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(incentive => ({
				label: incentive.name,
				terms: incentive.terms,
				value: incentive.id,
			})),
		)
)