import React from 'react'
import { Form } from 'react-bootstrap'
import { Col, Row } from 'reactstrap'

export default function IncentiveForm({ register, errors, onlyEditable = false }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre del incentivo <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register("name", { required: true })}
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Instrucciones para redimir <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register("instructions", { required: true })}
				/>
				{ errors.instructions && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Términos y condiciones <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register("terms", { required: true })}
				/>
				{ errors.terms && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>UTM Source <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('utm_source', { required: true })}
						disabled={onlyEditable}
					/>
					{ errors.utm_source && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>UTM Campaign <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('utm_campaign', { required: true })}
						disabled={onlyEditable}
					/>
					{ errors.utm_campaign && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Visibilidad</Form.Label>
				<Form.Control
					as="select"
					{...register('is_public', { required: true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="1">Visible para todos</option>
					<option value="0">Solo visible en administrador</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}