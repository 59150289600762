import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { Card, Divider } from 'antd'

import EmployeeTypesTable from './partials/EmployeeTypesTable'
import NewEmployeeTypeModal from './partials/NewEmployeeTypeModal'
import ImportEmployeeTypeEmployeesModal from './partials/ImportEmployeeTypeEmployeesModal'

import { getEmployeeTypes } from './services'
import { handleError } from '../../helpers'

export default function EmployeeTypesTab() {
	const [employeeTypes, setEmployeeTypes] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showImportModal, setShowImportModal] = useState(false)
	
	useEffect(() => {
		!employeeTypes && getEmployeeTypes({
			include: 'couponTypes,employeesCount'
		})
			.then(res => setEmployeeTypes(res.data.data))
			.catch(handleError)
	}, [ employeeTypes ])

	return (
		<React.Fragment>
			<Card style={{ maxWidth: '1200px' }}>
				<div className='mb-2'>
					<Button color='primary' outline onClick={() => setShowNewModal(true)}>
						Agregar Categoría Nueva
					</Button>
					<Divider type='vertical' />
					<Button color='primary' outline onClick={() => setShowImportModal(true)}>
						Asociar Categoría a Empleados Masivamente
					</Button>
				</div>
				<EmployeeTypesTable 
					employeeTypes={employeeTypes}
					reload={() => setEmployeeTypes(null)}
				/>
			</Card>
			{ showNewModal && (
				<NewEmployeeTypeModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setEmployeeTypes(null)}
				/>
			)}
			{ showImportModal && (
				<ImportEmployeeTypeEmployeesModal 
					visible
					onClose={() => setShowImportModal(false)}
					reload={() => setEmployeeTypes(null)}
				/>
			)}
		</React.Fragment>
	)
}