import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Result } from 'antd'

import ListTopBar from '../../components/ListTopBar'

import ProductRatesTable from './partials/ProductRatesTable'
import NewProductRateModal from './partials/NewProductRateModal'
import ImportProductRatesModal from './partials/ImportProductRatesModal'

import { getProductRates } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ProductRatesList() {
	const { user } = useSelector(state => state.auth)
	const [productRates, setProductRates] = useState(null)
	const [filterType, setFilterType] = useState('code_ln')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('active')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewRateModal, setShowNewRateModal] = useState(false)
	const [showImportRatesModal, setShowImportRatesModal] = useState(false)

	useEffect(() => {
		!productRates && getProductRates({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
		})
			.then(res => {
				setProductRates(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ productRates, pagination, filterType, filterValue, filterScope ])

	if(user.role !== 'admin' && user.role !== 'hr_admin')
		return <Result title="No tiene permisos para este módulo" status="403" />

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de tarifas de empleados"
				subtitle="Listado de productos habilitados para empleados"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setProductRates(null)}
				options={[
					{ label: 'Buscar por código LN', value: 'code_ln' },
					{ label: 'Buscar por linea de producto', value: 'product_line' },
					{ label: 'Buscar por descripción', value: 'product_desc' },
				]}
				scopes={[
					{ label: 'Tarifas activas', value: 'active' },
					{ label: 'Tarifas inactivas', value: 'inactive' },
				]}
				ctaText="Agregar Tarifa"
				handleCtaClick={() => setShowNewRateModal(true)}
				secundaryCtaText="Importar Tarifas"
				handleSecundaryCtaClick={() => setShowImportRatesModal(true)}
			/>
			<Card>
				<CardBody>
					<ProductRatesTable 
						productRates={productRates}
						reload={() => setProductRates(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRateModal && (
				<NewProductRateModal 
					visible
					onClose={() => setShowNewRateModal(false)}
					reload={() => setProductRates(null)}
				/>
			)}
			{ showImportRatesModal && (
				<ImportProductRatesModal 
					visible
					onClose={() => setShowImportRatesModal(false)}
					reload={() => setProductRates(null)}
				/>
			)}
		</React.Fragment>
	)
}