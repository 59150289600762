import React from 'react'
import { Badge } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

import { updateEmployeeReward } from '../services'
import { handleError } from '../../../helpers'

export default function EmployeeRewardsTable({ employeeRewards, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Redención No',
			dataIndex: 'id'
		},
		{
			title: 'Premio redimido',
			dataIndex: 'reward',
			render: t => t.name
		},
		{
			title: 'Otorgado a',
			dataIndex: 'employee',
			render: t => t.fullname
		},
		{
			title: 'Fecha de solicitud',
			dataIndex: 'created_at'			
		},
		{
			title: 'Fecha de confirmación',
			dataIndex: 'confirmed_at',
			render: t => t ? t : <em>Sin confirmar</em>
		},
		{
			title: 'Fecha de rechazo',
			dataIndex: 'rejected_at',
			render: t => t ? t : <em>Sin rechazo</em>
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					{ r.status.key === 'pendding' && (
						<React.Fragment>
							<Tooltip title="Confirmar">
								<Popconfirm
									title="¿Desea anular esta solicitud?"
									okText="Confirmar"
									cancelText="Cancelar"
									onConfirm={() => handleConfirm(r.id)}
								>
									<FontAwesomeIcon
										className='text-link'
										icon={faCheckDouble}
									/>
								</Popconfirm>
							</Tooltip>
							<Divider type="vertical" />
							<Tooltip title="Rechazar">
								<Popconfirm
									title="¿Desea rechazar esta solicitud?"
									okText="Rechazar"
									cancelText="Cancelar"
									onConfirm={() => handleReject(r.id)}
								>
									<FontAwesomeIcon
										className='text-link'
										icon={faTimes}
									/>
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
					{ r.status.key !== 'voided' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Anular">
								<Popconfirm
									title="¿Desea anular esta solicitud?"
									okText="Anular"
									cancelText="Cancelar"
									onConfirm={() => handleVoid(r.id)}
									okButtonProps={{ danger: true }}
								>
									<FontAwesomeIcon
										className='text-link'
										icon={faTimesCircle}
									/>
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]
	
	const handleVoid = id => {
		updateEmployeeReward(id, { voided_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	const handleConfirm = id => {
		updateEmployeeReward(id, { confirmed_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	const handleReject = id => {
		updateEmployeeReward(id, { reject_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={employeeRewards}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!employeeRewards}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}