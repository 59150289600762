import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { searchIncentives } from '../../Incentives/services'
import { searchEmployees } from '../../Employees/services'

export default function ReferralOrderForm({ register, errors, setValue }) {
	const [selectedEmployee, setSelectedEmployee] = useState([])
	const [selectedIncentive, setSelectedIncentive] = useState([])
	
	useEffect(() => {
		if(selectedEmployee.value) 
			setValue('employee_id', selectedEmployee.value)
	}, [ selectedEmployee, setValue ])
	
	useEffect(() => {
		if(selectedIncentive.value) 
			setValue('incentive_id', selectedIncentive.value)
	}, [ selectedIncentive, setValue ])

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Empleado quien refiere <span className='text-danger'>*</span></Form.Label>
				<ServerSideSelect
					value={selectedEmployee}
					placeholder="Buscar por nombre"
					fetchOptions={searchEmployees}
					onChange={(value, record) => setSelectedEmployee(record)}
					style={{ width: '100%', marginBottom: '7px' }}
				/>
				<Form.Control {...register('employee_id', { required:true })} style={{ display:'none' }} />
				{ errors.employee_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Nombre del Referido <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('referral_name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.referral_name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Cédula del Referido <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					type="number"
					{...register('referral_document', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.referral_document && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Email del Referido <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('referral_email', { required:true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.referral_email && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Celular del Referido <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('referral_mobile', { required:true })}
					placeholder="Escriba aquí..."
					type="number"
				/>
				{ errors.referral_mobile && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Incentivo <span className='text-danger'>*</span></Form.Label>
				<ServerSideSelect
					value={selectedIncentive}
					placeholder="Buscar incentivo"
					fetchOptions={searchIncentives}
					onChange={(value, record) => setSelectedIncentive(record)}
					style={{ width: '100%', marginBottom: '7px' }}
				/>
				<Form.Control {...register('incentive_id', { required:true })} style={{ display:'none' }} />
				{ selectedIncentive.terms && <Form.Text className='text-muted'>
					<strong>Términos y condiciones: </strong>
					{selectedIncentive.terms}
				</Form.Text> }
				{ errors.incentive_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Canal donde desea redimir <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register("channel", { required:true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="stores">Tiendas Spring</option>
					<option value="ecommerce">eCommerce Spring</option>
				</Form.Control>
				{ errors.channel && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}