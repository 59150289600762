import React from 'react'
import { Empty, Table } from 'antd'

import Pagination from '../../../components/Pagination'

export default function ReferralsTable({ referrals, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
		{
			title: 'Documento',
			dataIndex: 'document'
		},
		{
			title: 'Correo',
			dataIndex: 'email',
			render: t => t ? t : <em>Sin específicar</em>
		},
		{
			title: 'Registrado Por',
			dataIndex: 'employee',
			render: t => t.fullname
		},
		{
			title: 'Fecha de registro',
			dataIndex: 'created_at'
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={referrals}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!referrals}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}