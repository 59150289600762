import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditIncentiveModal from './EditIncentiveModal'

import { deleteIncentive, updateIncentive } from '../services'
import { handleError } from '../../../helpers'

export default function IncentivesTable({ incentives, reload }) {
	const [editIncentive, setEditIncentive] = useState(null)
	
	const columns = [
		{
			title: 'Incentivo No',
			dataIndex: 'id',
			width: '110px'
		},
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'UTM Source',
			dataIndex: 'utm_source',
		},
		{
			title: 'UTM Campaign',
			dataIndex: 'utm_campaign',
		},
		{
			title: 'Total activaciones',
			dataIndex: 'referral_orders_count',
			render: t => `${t} activaciones`
		},
		{
			title: 'Fecha desactivación',
			dataIndex: 'disabled_at',
			render: t => t ? t : <em>Incentivo activo</em>
		},
		{
			title: 'Visibilidad',
			dataIndex: 'is_public',
			render: t => t ? 'Visible para todos' : 'Solo administrador'
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon
							onClick={() => setEditIncentive(r)}
							className="text-link"
							icon={faEdit}
						/>
					</Tooltip>
					<Divider type="vertical" />
					{ r.status.key === 'active' ? (
						<Tooltip title="Desactivar" >
							<FontAwesomeIcon 
								className='text-link'
								icon={faToggleOff} 
								onClick={() => handleDisable(r.id)}
							/>
						</Tooltip>
					) : (
						<Tooltip title="Activar">
							<FontAwesomeIcon 
								className='text-link'
								icon={faToggleOn} 
								onClick={() => handleEnable(r.id)}
							/>
						</Tooltip>
					)}
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={() => handleDelete(r.id)}
						>
							<FontAwesomeIcon 
								className='text-link'
								icon={faTrash} 
							/>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteIncentive(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	const handleDisable = id => {
		updateIncentive(id, { disabled_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	const handleEnable = id => {
		updateIncentive(id, { disabled_at: '' })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'>
				<strong>Términos y condiciones:</strong>{" "}
				{r.terms}
			</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={incentives}
				rowKey={r => r.id}
				columns={columns}
				loading={!incentives}
				expandable={{ expandedRowRender }}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ editIncentive && (
				<EditIncentiveModal 
					visible
					onClose={() => setEditIncentive(null)}
					incentive={editIncentive}
					reload={reload}
				/> 
			)}
		</React.Fragment>
	)
}