import React from 'react'
import { Form } from 'react-bootstrap'

export default function ProductRateForm({ register, errors, onlyEditable = false }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Código LN 10.4<span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					type="number"
					{...register("code_ln", { required: true })}
					disabled={onlyEditable}
				/>
				{ errors.code_ln && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Linea Product <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register("product_line", { required: true })}
				/>
				{ errors.product_line && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción Product <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register("product_desc", { required: true })}
				/>
				{ errors.product_desc && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Precio Empleado <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register("employee_price", { required: true })}
					type="number"
				/>
				{ errors.employee_price && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Estado <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('is_active', { required:true })}
				>
					<option value="1">Activo</option>
					<option value="0">Inactivo</option>
				</Form.Control>
				{ errors.is_active && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}