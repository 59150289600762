import React from 'react'
import { Badge, Col, Row } from 'reactstrap'
import { Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

import { updateReferralOrder } from '../services'
import { handleError } from '../../../helpers'

export default function ReferralOrderTable({ referralOrders, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Solicitud No',
			dataIndex: 'id'
		},
		{
			title: 'Referido',
			dataIndex: 'referral',
			render: t => t.name
		},
		{
			title: 'Canal',
			dataIndex: 'channel',
			render: t => t === 'stores' ? 'Tienda Spring' : 'eCommerce Spring',
		},
		{
			title: 'Incentivo',
			dataIndex: 'incentive',
			render: t => t.name
		},
		{
			title: 'Cupon eCommerce',
			dataIndex: 'vtex_referral_code',
			render: t => t ? 'Asignado' : <em>No asignado</em>
		},
		{
			title: 'Colaborador remitente',
			dataIndex: 'referral',
			render : t => t.employee.fullname
		},
		{
			title: 'Fecha de envió',
			dataIndex: 'created_at',
		},
		{
			title: 'Fecha vencimiento',
			dataIndex: 'expire_at',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{ r.status.key !== 'completed' && r.status.key !== 'voided' && (
						<Tooltip title="Anular">
							<Popconfirm
								title="¿Desea anular esta solicitud?"
								okText="Anular"
								cancelText="Cancelar"
								onConfirm={() => handleVoid(r.id)} 
								okButtonProps={{ danger: true }}
							>
								<FontAwesomeIcon
									className='text-link'
									icon={faTimesCircle}
								/>
							</Popconfirm>
						</Tooltip>
					)}
				</React.Fragment>
			)
		}
	]
	
	const handleVoid = id => {
		updateReferralOrder(id, { voided_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<Row>
				<Col sm="6" md="4"><p className='mb-0'><strong>Cédula Referido: </strong>{r.referral.document}</p></Col>
				<Col sm="6" md="4"><p className='mb-0'><strong>Correo Referido: </strong>{r.referral.email ? r.referral.email : <em>Sin registro</em>}</p></Col>
				<Col sm="6" md="4"><p className='mb-0'><strong>Celular Referido: </strong>{r.referral.mobile ? r.referral.mobile : <em>Sin registro</em>}</p></Col>
				<Col sm="6" md="4"><p className='mb-0'><strong>Cédula Colaborador: </strong>{r.referral.employee.document}</p></Col>
			</Row>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={referralOrders}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!referralOrders}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}