import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import IncentivesTable from './partials/IncentivesTable'
import NewIncentiveModal from './partials/NewIncentiveModal'

import { handleError } from '../../helpers'
import { getIncentives } from './services'

export default function IncentivesList() {
	const [incentives, setIncentives] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('active')
	const [showNewIncentiveModal, setShowNewIncentiveModal] = useState(false)

	useEffect(() => {
		!incentives && getIncentives({
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'referralOrdersCount'
		})
			.then(res => setIncentives(res.data.data))
			.catch(error => handleError(error))
	}, [ incentives, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Catálogo de incentivos a referidos"
				subtitle="Incentivos habilitados para ofrecer a referidos"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setIncentives(null)}
				ctaText="Agregar Nuevo Incentivo"
				handleCtaClick={() => setShowNewIncentiveModal(true)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				scopes={[
					{ label: 'Incentivos activos', value: 'active' },
					{ label: 'Incentivos inactivos', value: 'inactive' },
				]}
			/>
			<Card>
				<CardBody>
					<IncentivesTable 
						incentives={incentives}
						reload={() => setIncentives(null)}
					/>
				</CardBody>
			</Card>
			{ showNewIncentiveModal && (
				<NewIncentiveModal 
					visible
					onClose={() => setShowNewIncentiveModal(false)}
					reload={() => setIncentives(null)}
				/>
			)}
		</React.Fragment>
	)
}