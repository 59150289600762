import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditRewardModal from './EditRewardModal'

import { deleteReward, updateReward } from '../services'
import { handleError, number } from '../../../helpers'

export default function RewardsTable({ rewards, reload }) {
	const [editReward, setEditReward] = useState(null)
	
	const columns = [
		{
			title: 'Premio No',
			dataIndex: 'id',
			width: '110px'
		},
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Pts requeridos',
			dataIndex: 'points_required',
			render: t => `${number(t)} puntos`
		},
		{
			title: 'Total redenciones',
			dataIndex: 'employee_rewards_count',
			render: t => `${t} redenciones`
		},
		{
			title: 'Fecha creación',
			dataIndex: 'created_at',
		},
		{
			title: 'Fecha desactivación',
			dataIndex: 'disabled_at',
			render: t => t ? t : <em>Premio activo</em>
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon
							onClick={() => setEditReward(r)}
							className="text-link"
							icon={faEdit}
						/>
					</Tooltip>
					<Divider type="vertical" />
					{ r.status.key === 'active' ? (
						<Tooltip title="Desactivar" >
							<FontAwesomeIcon 
								className='text-link'
								icon={faToggleOff} 
								onClick={() => handleDisable(r.id)}
							/>
						</Tooltip>
					) : (
						<Tooltip title="Activar">
							<FontAwesomeIcon 
								className='text-link'
								icon={faToggleOn} 
								onClick={() => handleEnable(r.id)}
							/>
						</Tooltip>
					)}
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={() => handleDelete(r.id)}
						>
							<FontAwesomeIcon 
								className='text-link'
								icon={faTrash} 
							/>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteReward(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	const handleDisable = id => {
		updateReward(id, { disabled_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	const handleEnable = id => {
		updateReward(id, { disabled_at: '' })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'>
				<strong>Términos y condiciones:</strong>{" "}
				{r.terms}
			</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={rewards}
				rowKey={r => r.id}
				columns={columns}
				loading={!rewards}
				expandable={{ expandedRowRender }}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ editReward && (
				<EditRewardModal 
					visible
					onClose={() => setEditReward(null)}
					reward={editReward}
					reload={reload}
				/> 
			)}
		</React.Fragment>
	)
}