import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import OrderInvoicesTable from './partials/OrderInvoicesTable'

import { handleError, parsePagination } from '../../helpers'
import { getOrderInvoices } from './services'

export default function OrderInvoicesList({ scope }) {
	const [orderInvoices, setOrderInvoices] = useState(null)
	const [filterType, setFilterType] = useState('invoice_reference')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => setOrderInvoices(null), [ scope ])

	useEffect(() => {
		!orderInvoices && getOrderInvoices({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			[`filter[${scope}]`]: 1,
			include: 'couponOrder,referralOrder'
		})
			.then(res => {
				setOrderInvoices(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ orderInvoices, pagination, filterType, filterValue, filterScope, scope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title={scope === 'coupons' ? 'Listado de ventas con cupos' : 'Listado de ventas a referidos'}
				subtitle="Ventas registradas en la plataforma Venta de Empleados Spring"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setOrderInvoices(null)}
				options={[
					{ label: 'Buscar por número de factura', value: 'invoice_reference' },
				]}
				scopes={[
					{ label: 'Ventas en proceso', value: 'pendding' },
					{ label: 'Ventas confirmadas', value: 'confirmed' },
					{ label: 'Ventas anuladas', value: 'voided' },
				]}
			/>
			<Card>
				<CardBody>
					<OrderInvoicesTable 
						scope={scope}
						orderInvoices={orderInvoices}
						orderKey={scope === 'coupons' ? 'coupon_order' : 'referral_order'}
						reload={() => setOrderInvoices(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}