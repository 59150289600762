import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import CouponOrderForm from './CouponOrderForm'

import { updateCouponOrder } from '../services'
import { handleError } from '../../../helpers'

export default function EditCouponOrderModal({ visible, onClose, couponOrder, reload }) {
	const { handleSubmit, register, formState: { errors }, watch } = useForm({
		defaultValues: {
			channel: couponOrder.channel,
			payment_method: couponOrder.payment_method,
			credit_payments: couponOrder.credit_payments,
			product_desc: couponOrder.product_desc,
		}
	})

	const onSubmit = values => {
		updateCouponOrder(couponOrder.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(handleError)
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar solicitud</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<CouponOrderForm 
						register={register}
						errors={errors}
						watch={watch}
						onlyEditable
					/>
					<Button color="primary" type="submit">
						Actualizar solicitud
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}