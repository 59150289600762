import React, { useState } from 'react'
import { Badge, Col, Row } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faEdit, faMoneyBill, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditCouponOrderModal from './EditCouponOrderModal'
import Pagination from '../../../components/Pagination'

import { updateCouponOrder } from '../services'
import { handleError, number } from '../../../helpers'
import NewOrderInvoiceModal from '../../OrderInvoices/partials/NewOrderInvoiceModal'

export default function CouponOrderTable({ couponOrders, reload, pagination, setPagination }) {
	const [editOrderModal, setEditOrderModal] = useState(null)
	const [orderInvoiceModal, setOrderInvoiceModal] = useState(null)
	
	const columns = [
		{
			title: 'Solicitud No',
			dataIndex: 'id'
		},
		{
			title: 'Cupon No',
			dataIndex: 'coupon',
			render: t => t.sku
		},
		{
			title: 'Canal',
			dataIndex: 'channel',
			render: t => t === 'stores' ? 'Tienda Spring' : 'eCommerce Spring',
		},
		{
			title: 'Medio de Pago',
			dataIndex: 'payment_method_name',
		},
		{
			title: 'Solicitado por',
			dataIndex: 'coupon',
			render: t => t.employee.fullname
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					{ r.status.key === 'authorization_pendding' && (
						<React.Fragment>
							<Tooltip title="Editar">
								<FontAwesomeIcon 
									className='text-link'
									icon={faEdit}
									onClick={() => setEditOrderModal(r)}
								/>
							</Tooltip>
							<Divider type="vertical" />
						</React.Fragment>
					)}
					{ r.status.key === 'authorization_pendding' && (
						<React.Fragment>
							<Tooltip title="Autorizar">
								<Popconfirm
									title="¿Desea autorizar esta solicitud?"
									okText="Autorizar"
									cancelText="Cancelar"
									onConfirm={() => handleAuthorize(r.id)}
								>
									<FontAwesomeIcon 
										className='text-link'
										icon={faCheckDouble}
									/>
								</Popconfirm>
							</Tooltip>
							<Divider type="vertical" />
						</React.Fragment>
					)}
					{ r.status.key === 'authorized' && (
						<React.Fragment>
							<Tooltip title="Ingresar Venta">
								<FontAwesomeIcon 
									className='text-link'
									icon={faMoneyBill}
									onClick={() => setOrderInvoiceModal(r)}
								/>
							</Tooltip>
							<Divider type="vertical" />
						</React.Fragment>
					)}
					{ (r.status.key === 'authorization_pendding' || r.status.key === 'authorized' || r.status.key === 'pendding') && (
						<React.Fragment>
							<Tooltip title="Anular">
								<Popconfirm
									title="¿Desea anular esta solicitud?"
									okText="Anular"
									cancelText="Cancelar"
									onConfirm={() => handleVoid(r.id)}
									okButtonProps={{ danger: true }}
								>
									<FontAwesomeIcon 
										className='text-link'
										icon={faTimesCircle}
									/>
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleAuthorize = id => {
		updateCouponOrder(id, { authorized_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				message.info(res.data.vtex_message)
				reload()
			})
			.catch(handleError)
	}
	
	const handleVoid = id => {
		updateCouponOrder(id, { voided_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<Row>
				<Col>
					<p className='mb-0'><strong>Producto solicitado</strong>: {r.product_rate ? r.product_rate.product_desc : 'sin asignar'}</p>
					<p className='mb-0'><strong>Tarifa empleado</strong>: {r.product_rate ? number(r.product_rate.employee_price) : 'sin asignar'}</p>
					<p className='mb-0'><strong>Cupon eCommerce</strong>: {r.vtex_coupon_code ? r.vtex_coupon_code : 'sin asignar'}</p>
					<p className='mb-0'><strong>Solicitado por</strong>: {r.coupon.employee.fullname} (CC {r.coupon.employee.document} | Email: {r.coupon.employee.email})</p>
					<p className='mb-0'><strong>Pago por descuento de nómina</strong>: {r.payment_method === 'salary' ? 
					<span>{r.credit_payments} cuotas | Referencia: {r.product_rate.product_desc}</span> : 'No'}</p>
				</Col>
				<Col>
					<p className='mb-0'><strong>Fecha de solicitud</strong>: {r.created_at}</p>
					<p className='mb-0'><strong>Fecha de vencimiento</strong>: {r.expire_at}</p>
					<p className='mb-0'><strong>Fecha de autorización</strong>: {r.authorized_at ? r.authorized_at : 'Sin autorizar'}</p>
				</Col>
			</Row>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={couponOrders}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!couponOrders}
				expandable={{ expandedRowRender }}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editOrderModal && (
				<EditCouponOrderModal 
					visible
					onClose={() => setEditOrderModal(null)}
					reload={reload}
					couponOrder={editOrderModal}
				/>
			)}
			{ orderInvoiceModal && (
				<NewOrderInvoiceModal 
					visible
					onClose={() => setOrderInvoiceModal(null)}
					reload={reload}
					couponOrder={orderInvoiceModal}
				/>
			)}
		</React.Fragment>
	)
}