import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import ReferralOrdersTable from './partials/ReferralOrdersTable'
import NewReferralOrderModal from './partials/NewReferralOrderModal'

import { handleError, parsePagination } from '../../helpers'
import { getReferralOrders } from './services'

export default function ReferralOrdersList() {
	const [referralOrders, setReferralOrders] = useState(null)
	const [filterType, setFilterType] = useState('referral.employee.document')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewReferralOrderModal, setShowNewReferralOrderModal] = useState(false)

	useEffect(() => {
		!referralOrders && getReferralOrders({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'incentive,referral.employee',
			sort: '-created_at'
		})
			.then(res => {
				setReferralOrders(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ referralOrders, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de incentivos enviados"
				subtitle="Listado de todos los incentivos enviados a referidos"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setReferralOrders(null)}
				options={[
					{ label: 'Buscar por cédula de empleado', value: 'referral.employee.document' },
					{ label: 'Buscar por nombre de empleado', value: 'referral.employee.name' },
					{ label: 'Buscar por nombre de incentivo', value: 'incentive.name' },
				]}
				scopes={[
					{ label: 'Solicitudes pendientes', value: 'pendding' },
					{ label: 'Solicitudes completadas', value: 'completed' },
					{ label: 'Solicitudes vencidas', value: 'expired' },
					{ label: 'Solicitudes anuladas', value: 'voided' },
				]}
				ctaText="Enviar Incentivo"
				handleCtaClick={() => setShowNewReferralOrderModal(true)}
			/>
			<Card>
				<CardBody>
					<ReferralOrdersTable 
						referralOrders={referralOrders}
						reload={() => setReferralOrders(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewReferralOrderModal && (
				<NewReferralOrderModal 
					visible
					onClose={() => setShowNewReferralOrderModal(false)}
					reload={() => setReferralOrders(null)}
				/>
			)}
		</React.Fragment>
	)
}