import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import ReferralsTable from './partials/ReferralsTable'

import { handleError, parsePagination } from '../../helpers'
import { getReferrals } from './services'

export default function ReferralsList() {
	const [referrals, setReferrals] = useState(null)
	const [filterType, setFilterType] = useState('document')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!referrals && getReferrals({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'employee'
		})
			.then(res => {
				setReferrals(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ referrals, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de Referidos Registrados"
				subtitle="Listado de todos los referidos registrados por empleados Spring"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setReferrals(null)}
				options={[
					{ label: 'Buscar por cédula', value: 'document' },
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por correo electrónico', value: 'email' },
				]}
			/>
			<Card>
				<CardBody>
					<ReferralsTable 
						referrals={referrals}
						reload={() => setReferrals(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}