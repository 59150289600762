import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTags, faTrash } from '@fortawesome/free-solid-svg-icons'
import { deleteEmployeeType } from '../services'
import { handleError } from '../../../helpers'
import EditEmployeeTypeModal from './EditEmployeeTypeModal'
import RelatedCouponsTypesModal from './RelatedCouponsTypesModal'

export default function EmployeeTypesTable({ employeeTypes, reload }) {
	const [editEmployeeType, setEditEmployeeType] = useState(null)
	const [employeeTypeCoupons, setEmployeeTypeCoupons] = useState(null)

	const columns = [
		{
			title: 'ID Categoría',
			dataIndex: 'id'
		},
		{
			title: 'Categoría',
			dataIndex: 'name'
		},
		{
			title: 'Total Empleados Inscritos',
			dataIndex: 'employees_count',
			render: t => `${t} empleados`
		},
		{
			title: 'Cupos Otorgados',
			dataIndex: 'coupon_types',
			render: t => (
				<React.Fragment>
					{ t.length > 0 ? (
						<React.Fragment>
							{ t.map( couponType => (
								<p className='mb-0' key={couponType.id}>
									{couponType.name} - {couponType.pivot.quantity}
								</p>
							)) }
						</React.Fragment>
					) : (
						<small><em>No otorga cupos</em></small>
					)}
				</React.Fragment>
			)
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={() => setEditEmployeeType(r)}>
						<FontAwesomeIcon 
							icon={faEdit}
							className="text-link"							
						/>
						<span className='ml-1 text-link'>Editar</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Asociar Cupos" onClick={() => setEmployeeTypeCoupons(r)}>
						<FontAwesomeIcon 
							icon={faTags}
							className="text-link"							
						/>
						<span className='ml-1 text-link'>Asociar Cupos</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={() => handleDelete(r.id)}
						>
							<FontAwesomeIcon 
								icon={faTrash}
								className="text-link"
							/>
							<span className='ml-1 text-link'>Eliminar</span>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteEmployeeType(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={employeeTypes}
				loading={!employeeTypes}
				columns={columns}
				rowKey={r => r.id}
				size="small"
				locale={{ emptyText: (
					<Empty description="Sin registros encontrados" imageStyle={{ maxHeight: '70px' }} />
				)}}
			/>
			{ editEmployeeType && (
				<EditEmployeeTypeModal 
					visible
					onClose={() => setEditEmployeeType(null)}
					employeeType={editEmployeeType}
					reload={reload}
				/>
			)}
			{ employeeTypeCoupons && (
				<RelatedCouponsTypesModal 
					visible
					onClose={() => setEmployeeTypeCoupons(null)}
					employeeType={employeeTypeCoupons}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}