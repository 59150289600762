import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import IncentiveForm from './IncentiveForm'

import { storeIncentive } from '../services'
import { handleError } from '../../../helpers'

export default function NewIncentiveModal({ visible, onClose, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm()

	const onSubmit = values => {
		storeIncentive(values)	
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(handleError)
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Agregar nuevo incentivo</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<IncentiveForm 
						register={register}
						errors={errors}
					/>
					<Button color="primary" type="submit">
						Agregar incentivo
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}