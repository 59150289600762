import React from 'react'
import { Form } from 'react-bootstrap'

export default function RewardForm({ register, errors, onlyEditable = false }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre del premio <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register("name", { required: true })}
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Puntos requeridos <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					type="number"
					{...register("points_required", { required: true })}
				/>
				{ errors.points_required && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Términos y condiciones <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register("terms", { required: true })}
				/>
				{ errors.terms && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}