import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'

import { login } from './services'
import { handleError } from '../../helpers'
import { userLogin } from './actions'

export default function Login() {
	const { handleSubmit, register, formState: { errors } } = useForm()
	const dispatch = useDispatch()

	const onSubmit = values => {
		login(values)
			.then(res => {
				dispatch(userLogin(res.data))
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<h5 className="mb-2">Iniciar Sesión</h5>
			<p className="text-muted mb-4">Bienvenido de nuevo. Ingrese su cédula y contraseña para ingresar al aplicativo.</p>
			<Form className="text-left" onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Label>Número de Cédula <span className="text-primary">*</span></Form.Label>
					<Form.Control 
						type="number"
						placeholder="Escriba su documento"
						{...register('document', { required: true })}
					/>
					{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group>
					<Form.Label>Contraseña <span className="text-primary">*</span></Form.Label>
					<Form.Control
						type="password"
						placeholder="Escriba su contraseña"
						{...register('password', { required: true })}
					/>
					{ errors.password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Button color="primary" className="full-width">Iniciar Sesión</Button>
			</Form>
		</React.Fragment>
	)
}