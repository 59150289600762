import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../../components/Pagination'
import EditProductRateModal from './EditProductRateModal'

export default function ProductRatesTable({ productRates, reload, pagination, setPagination }) {
	const [editProductRateModal, setEditProductRateModal] = useState(null)
	
	const columns = [
		{
			title: 'Código LN Producto',
			dataIndex: 'code_ln'
		},
		{
			title: 'Linea',
			dataIndex: 'product_line'
		},
		{
			title: 'Descripción',
			dataIndex: 'product_desc'
		},
		{
			title: 'Talla',
			dataIndex: 'size'
		},
		{
			title: 'Precio Empleado',
			dataIndex: 'employee_price'
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							icon={faEdit}
							onClick={() => setEditProductRateModal(r)}
							className='text-link'
						/>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={productRates}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!productRates}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editProductRateModal && (
				<EditProductRateModal 
					visible
					onClose={() => setEditProductRateModal(null)}
					productRate={editProductRateModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}