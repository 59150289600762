import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ProductRateForm from './ProductRateForm'

import { updateProductRate } from '../services'
import { handleError } from '../../../helpers'

export default function EditProductRateModal({ visible, onClose, productRate, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: productRate
	})

	const onSubmit = values => {
		updateProductRate(productRate.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(handleError)
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar tarifa</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ProductRateForm 
						register={register}
						errors={errors}
						onlyEditable
					/>
					<Button color="primary" type="submit">
						Actualizar tarifa
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}