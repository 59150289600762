import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, InputGroup, Spinner } from 'react-bootstrap'
import { Empty, message } from 'antd'

import { getCouponTypes, storeCouponTypeEmployeeType } from '../services'
import { handleError } from '../../../helpers'

export default function RelatedCouponsTypesModal({ visible, onClose, employeeType, reload }) {
	const [couponTypes, setCouponTypes] = useState(null)
	const [form, setForm] = useState({})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		!couponTypes && getCouponTypes()
			.then(res => {
				setCouponTypes(res.data.data)
				res.data.data.map(opt => {
					let currentValue = employeeType.coupon_types.filter(ct => ct.id === opt.id)
					return setForm({ ...form, [opt.id]: currentValue.length > 0 ? currentValue[0].pivot.quantity : 0 })
				})				
			})
			.catch(handleError)
	}, [ couponTypes, form, employeeType ])

	const onSubmit = async () => {
		setLoading(true)
		Object.keys(form).forEach(async couponTypeId => {
			await storeCouponTypeEmployeeType({
				coupon_type_id: couponTypeId,
				employee_type_id: employeeType.id,
				quantity: parseInt(form[couponTypeId])
			}).catch(handleError)
		})
		message.info('Cantidades actualizadas')
			.then(() => {
				reload()
				onClose()
			})
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Cupos asignados</ModalHeader>
			<ModalBody>
				{ couponTypes ? (
					<React.Fragment>
						{ couponTypes.length > 0 ? (
							<React.Fragment>
								{ couponTypes.map(couponType => (
									<Form.Group key={couponType.id}>
										{ form[couponType.id] !== null && (
											<InputGroup>
												<InputGroup.Text>{couponType.name}</InputGroup.Text>
												<Form.Control 
													type="number"
													value={form[couponType.id]}
													onChange={e => setForm({ ...form, [couponType.id]: e.target.value })}
												/>
												<InputGroup.Text>cupos</InputGroup.Text>
											</InputGroup>
										)}
									</Form.Group>
								)) }
								<Button color='primary' onClick={onSubmit} disabled={loading}>
									{loading && <Spinner animation='border' size="sm" className='mr-2' />}{" "}
									Actualizar valores
								</Button>
							</React.Fragment>
						) : (
							<Empty description="No existen tipos de cupones" />
						)}
					</React.Fragment>
				) : (
					<Spinner animation="border" size="sm" />
				)}
			</ModalBody>
		</Modal>
	)
}