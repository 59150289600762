import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import CouponsTable from './partials/CouponsTable'

import { handleError, parsePagination } from '../../helpers'
import { getCoupons } from './services'

export default function CouponsList() {
	const [coupons, setCoupons] = useState(null)
	const [filterType, setFilterType] = useState('sku')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!coupons && getCoupons({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'employee,couponType'
		})
			.then(res => {
				setCoupons(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ coupons, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de Cupos Otorgados"
				subtitle="Cupos otorgados de forma automática por la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setCoupons(null)}
				options={[
					{ label: 'Buscar por identificador', value: 'sku' },
					{ label: 'Buscar por cédula', value: 'employee.document' },
					{ label: 'Buscar por nombre', value: 'employee.name' },
				]}
				scopes={[
					{ label: 'Cupos válidos', value: 'valid' },
					{ label: 'Cupos vencidos', value: 'expired' },
					{ label: 'Cupos utilizados', value: 'used' },
					{ label: 'Cupos anulados', value: 'voided' },
				]}
			/>
			<Card>
				<CardBody>
					<CouponsTable 
						coupons={coupons}
						reload={() => setCoupons(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}