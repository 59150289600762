import springApi, { getDomain } from '../../services/springApi'

export const getEmployeeTypes = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/employee_types', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getEmployeeType = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/employee_types/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeEmployeeType = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/employee_types', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateEmployeeType = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/employee_types/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteEmployeeType = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/employee_types/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchEmployeeTypes = async (name, token) => (
	fetch( getDomain()+`/employee_types?filter[name]=${name}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(employee_type => ({
				label: employee_type.fullname,
				value: employee_type.id,
			})),
		)
)

export const getCouponTypes = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/coupon_types', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCouponTypeEmployeeType = data => (
	new Promise((resolve, reject) => { 
		springApi()
			.post('coupon_type_employee_type', data)	
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const importEmployeeTypeEmployees = data => (
	new Promise((resolve, reject) => { 
		springApi()
			.post('employee_type_employees/import', data)	
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)