import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import ReferralOrderForm from './ReferralOrderForm'

import { storeReferral } from '../../Referrals/services'
import { storeReferralOrder } from '../services'
import { handleError } from '../../../helpers'

export default function NewReferralOrderModal({ visible, onClose, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()

	const onSubmit = values => {
		values.expire_at = moment().add(1,'month').format('YYYY-MM-DD HH:mm:ss')
		storeReferral({ 
			employee_id: values.employee_id,
			name: values.referral_name, 
			document: values.referral_document, 
			email: values.referral_email,
			mobile: values.referral_mobile,
		})
			.then(res => {
				storeReferralOrder({...values, referral_id: res.data.data.id})
					.then(res => {
						message.success(res.data.message)
						reload()
						onClose()
					})
					.catch(handleError)
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Enviar Incentivo</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ReferralOrderForm 
							register={register}
							errors={errors}
							setValue={setValue}
						/>
						<Button color="primary" type="submit">
							Enviar Incentivo
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}