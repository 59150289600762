import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Result } from 'antd'

import ListTopBar from '../../components/ListTopBar'

import CouponOrdersTable from './partials/CouponOrdersTable'

import { handleError, parsePagination } from '../../helpers'
import { getCouponOrders } from './services'

export default function CouponOrdersList() {
	const { user } = useSelector(state => state.auth)
	const [couponOrders, setCouponOrders] = useState(null)
	const [filterType, setFilterType] = useState('coupon.employee.document')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!couponOrders && getCouponOrders({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'coupon.couponType,coupon.employee,productRate'
		})
			.then(res => {
				setCouponOrders(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ couponOrders, pagination, filterType, filterValue, filterScope ])

	if(user.role !== 'admin' && user.role !== 'hr_admin')
		return <Result title="No tiene permisos para este módulo" status="403" />

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de Cupos Solicitados"
				subtitle="Solicitudes de utilización de cupos recibidas"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setCouponOrders(null)}
				options={[
					{ label: 'Buscar por cédula de empleado', value: 'coupon.employee.document' },
					{ label: 'Buscar por nombre de empleado', value: 'coupon.employee.name' },
				]}
				scopes={[
					{ label: 'Solicitudes pendientes', value: 'pendding' },
					{ label: 'Solicitudes pendientes de autorizar', value: 'authorization_pendding' },
					{ label: 'Solicitudes autorizadas', value: 'authorized' },
					{ label: 'Solicitudes vencidas', value: 'expired' },
					{ label: 'Solicitudes completadas', value: 'completed' },
					{ label: 'Solicitudes anuladas', value: 'voided' },
				]}
			/>
			<Card>
				<CardBody>
					<CouponOrdersTable 
						couponOrders={couponOrders}
						reload={() => setCouponOrders(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}