import springApi, { getDomain } from '../../services/springApi'

export const getSettings = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/settings', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSetting = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/settings/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSetting = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/settings', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSetting = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/settings/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSetting = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/settings/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchSettings = async (name, token) => (
	fetch( getDomain()+`/settings?filter[name]=${name}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(setting => ({
				label: setting.fullname,
				value: setting.id,
			})),
		)
)