import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'

import PointsFactorTab from './partials/PointsFactorTab'
import EmployeeTypesTab from '../EmployeeTypes/EmployeeTypesTab'

export default function SettingsHomePage(props) {
	const selectedTab = props.match.params.tab
	const history = useHistory()

	return (
		<React.Fragment>
			<Tabs
				tabPosition="top"
				size="middle"
				type="card"				
			>
				<Tabs.TabPane 
					key="points_factor"
					tab="Puntos por venta"
					active={selectedTab === 'points_factor'}
					onClick={() => history.push(`/settings/points_factor`)}
				>
					<PointsFactorTab />
				</Tabs.TabPane>
				<Tabs.TabPane 
					key="employee_types"
					tab="Categorías de Empleados" 
					active={selectedTab === 'employee_types'}
					onClick={() => history.push(`/settings/employee_types`)}
				>
					<EmployeeTypesTab />
				</Tabs.TabPane>
			</Tabs>
		</React.Fragment>
	)
}

