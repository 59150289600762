import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'

import DownloadReportModal from './partials/DownloadReportModal'

import PageTitle from '../../components/PageTitle'
import MenuWidget from '../../components/MenuWidget'

export default function ReportsDashboard() {
	const [reportModal, setReportModal] = useState(null)

	return (
		<React.Fragment>
			<PageTitle 
				title="Reportes Administradores" 
				subtitle="Descarga de reportes Plataforma Venta Empleados Spring." 
			/>
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-3">
					<MenuWidget 
						title="Reporte de Auditoria"
						subtitle="Reporte de auditoria de venta a empleados Spring"
						icon={faFileSignature}
						onClick={() => setReportModal({
							title: 'Reporte de Auditoria',
							slug: 'audit_report',
						})}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-3">
					<MenuWidget 
						title="Solicitudes Recibidas"
						subtitle="Exportar solicitudes de uso de cupos recibidas"
						icon={faFileSignature}
						onClick={() => setReportModal({
							title: 'Solicitudes de Uso Recibidas',
							slug: 'coupon_orders',
						})}
					/>
				</Col>
			</Row>
			{ reportModal && (
				<DownloadReportModal 
					visible
					onClose={() => setReportModal(null)}
					data={reportModal}
				/>
			)}
		</React.Fragment>
	)
}