import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { Alert, message } from 'antd'
import { useSelector } from 'react-redux'

import { storeDownloadToken } from '../../DownloadTokens/services'
import { getUri } from '../../../services/springApi'
import { handleError } from '../../../helpers'

export default function DownloadReportModal({ visible, onClose, data }) {
	const user_id = useSelector(state => state.auth.user.id)
	const [loading, setLoading] = useState(false)

	const onSubmit = () => {
		setLoading(true)
		let token = new Date().getTime()
		storeDownloadToken({ type: `Descarga de ${data.title}`, token, user_id })
			.then(() => {
				message.info('Generando reporte en nueva pestaña')
				window.open(`${getUri()}/reports/export/${data.slug}/${token}`)
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>
					Descargar {data.title}
				</ModalHeader>
				<ModalBody>
					<Alert 
						type="info" 
						description="Al descargar el informe se generará un archivo excel en otra pestaña de su navegador." 
					/>
					<Button 
						className='mt-2' 
						color="primary" 
						onClick={onSubmit} 
						disabled={loading}
					>
						Descargar Reporte{" "}
						{ loading && <Spinner size="sm" className='ml-2' /> }
					</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}