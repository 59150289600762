import React, { useRef, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import * as d3 from 'd3'
import { Alert, message } from 'antd'

import { importProductRates } from '../services'
import { handleError } from '../../../helpers'

export default function ProductRateImportModal({ visible = true, onClose, reload }) {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState(null)
	const [results, setResults] = useState({})

	const uploadFile = useRef(null)
	
	const handleFiles = () => {
		(async () => {
			const text = await uploadFile.current.files[0].text()
			if(uploadFile.current.files[0].type !== 'text/csv')
				return message.warning('Favor ingresar únicamente archivos .csv')
				
			setData(d3.csvParse(text))
		})()
	}

	const handleSubmit = () => {
		setLoading(true)
		
		importProductRates({ rows: JSON.stringify(data) })
			.then(res => {
				message.success(res.data.message)
				setResults(res.data.data)
				reload()
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Importar Tarifas Masivamente</ModalHeader>
			<ModalBody>
				<input ref={uploadFile} onChange={handleFiles} type="file" className='block mb-2' />
				<Alert type='warning' description="Alerta: Si una tarifa con el mismo codigo y producto ya se habia registrado se actualizará sus datos sobrescribiendo los anteriores" />
				<Button 
					color="primary" 
					onClick={handleSubmit} 
					disabled={!data || loading}
					className="mt-2"
				>
					Importar Tarifas{" "}
					{ loading && <Spinner size="sm" className='ml-3' /> }
				</Button>
				<div className='mt-2 py-3 bg-light'>
					<ol>
						{ results.length > 0 && (
							<React.Fragment>
								{ results.map((result, i) => (
									<li key={i}>
										<strong>Fila #0{result.row}: </strong>{result.comment}
									</li>
								))}
							</React.Fragment>
						)}
					</ol>
				</div>
			</ModalBody>
		</Modal>
	)
}