import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import EmployeesTable from './partials/EmployeesTable'

import { handleError, parsePagination } from '../../helpers'
import { getEmployees } from './services'

export default function EmployeesList() {
	const [employees, setEmployees] = useState(null)
	const [filterType, setFilterType] = useState('document')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('active')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!employees && getEmployees({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			appends: 'employee_type',
		})
			.then(res => {
				setEmployees(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ employees, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Directorio de Empleados Spring"
				subtitle="Listado sincronizado con plataforma Soy Spring"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setEmployees(null)}
				options={[
					{ label: 'Buscar por cédula', value: 'document' },
					{ label: 'Buscar por email', value: 'email' },
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				scopes={[
					{ label: 'Empleados activos', value: 'active' },
					{ label: 'Empleados inactivos', value: 'inactive' },
				]}
			/>
			<Card>
				<CardBody>
					<EmployeesTable 
						employees={employees}
						reload={() => setEmployees(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}